<template>
  <sign-page
    title-text="审批人分配"
    :request="request"
    :column-list="columnList"
    :title-menus="[]"
    table-size="large"
    :form-parms-add="formParmsAdd"
    :data-file-parm="{pDirPath: '/ask_leave/'}"
    :tableActions="tableActions"
    :dealSearchParm="dealSearchParm"
    :form-parms-update="formParmsUpdate"
    :tableActionsFixed="true"
    :need-data-file="true">
  </sign-page>
</template>

<script>
import {
  askLeaveRequest as request
} from '@/api'

export default {
  created () {
    this.$store.dispatch('loadWorkerList')
  },
  methods: {
    dealSearchParm () {
      return {
        status: '待审批'
      }
    }
  },
  computed: {
    tableActions () {
      return [{
        key: 'data_file',
        label: '附件'
      },
      {
        key: 'edit',
        label: '分配',
        show: (data) => {
          return data.status === '待审批'
        }
      }]
    },
    formParmsAdd: {
      get () {
        let data = [{
          type: 'input',
          label: '请假时长',
          key: 'timeLong',
          fmDisabled: true
        },
        {
          type: 'select',
          label: '审批人',
          getSelectDatas: (formData) => {
            return this.$store.getters.workerList.filter(v => v.data.id !== formData.workerId)
          },
          key: 'spWorkerId',
          check: {
            required: true
          }
        }]
        return data
      }
    },
    formParmsUpdate: {
      get () {
        let data = []
        this.formParmsAdd.forEach((item) => {
          let newItem = {}
          for (let key in item) {
            newItem[key] = item[key]
          }
          data.push(newItem)
        })
        return data
      }
    },
    columnList () {
      let data = [{
        title: '编号',
        sort: true,
        field: 'id'
      },
      {
        title: '员工姓名',
        sort: true,
        field: 'workerName'
      },
      {
        title: '员工工号',
        sort: true,
        field: 'workerCode'
      },
      {
        title: '请假类型',
        sort: true,
        field: 'type',
        filters: ['年假', '事假', '病假', '调休假', '婚假', '产假', '陪产假', '其他'].map(v => {
          return {
            label: v,
            value: v
          }
        })
      },
      {
        title: '申请时间',
        width: 130,
        sort: true,
        field: 'pushTime',
        render: (h, rowData) => {
          return h('div', rowData && rowData.pushTime ? rowData.pushTime.slice(0, 16) : '-')
        }
      },
      {
        title: '开始时间',
        width: 130,
        sort: true,
        field: 'startTime',
        render: (h, rowData) => {
          return h('div', rowData && rowData.startTime ? rowData.startTime.slice(0, 16) : '-')
        }
      },
      {
        title: '结束时间',
        width: 130,
        sort: true,
        field: 'endTime',
        render: (h, rowData) => {
          return h('div', rowData && rowData.endTime ? rowData.endTime.slice(0, 16) : '-')
        }
      },
      {
        title: '请假时长',
        sort: true,
        field: 'timeLong'
      },
      {
        title: '请假事由',
        sort: true,
        field: 'reson'
      },
      {
        title: '审批人',
        sort: true,
        field: 'spWorkerName'
      },
      {
        title: '状态',
        sort: true,
        field: 'sprStatus',
        render: (h, rowData) => {
          return h('div', rowData && rowData.spWorkerId ? '已分配' : '未分配')
        },
        filterMethod: ({row, value}) => {
          return (row && row.spWorkerId ? '已分配' : '未分配').indexOf(value) > -1
        }
      }]
      return data
    }
  },
  data () {
    return {
      request
    }
  }
}
</script>
